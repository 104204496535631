<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-dialog
      v-model="carga"
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text class="text-h4 font-weight-light white--text">
          CARGANDO DATA...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <div class="d-flex flex-column justify-space-between align-center">
      <span class="text-h3 font-weight-bold mt-2 ml-2 text-center">
        RANKING DE PRODUCCIÓN JURISDICCIONAL - {{ anoRanking }}
      </span>
    </div>
    <v-row
      v-if="!carga"
      justify="center"
    >
      <v-col
        cols="12"
        md="4"
      >
        <base-material-card
          icon="mdi-chart-pie-outline"
          color="teal"
          title="DISTRIBUCIÓN"
        >
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-autocomplete
                v-model="tipoOrganos"
                :items="itemsTipos"
                label="Ver distribución por especialidad"
                @input="leerInfoProduccion(+currentYear, tipoOrganos)"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-autocomplete
                v-if="itemsSubEspecialidad.length > 0 && itemsSubEspecialidad[0] !== ' '"
                v-model="tipoSubEspecialidad"
                :items="itemsSubEspecialidad"
                label="Ver distribución por subespecialidad"
                @input=" leerDataSubespecialidad(tipoSubEspecialidad)"
              />
            </v-col>
          </v-row>
          <v-simple-table dense>
            <thead>
              <tr>
                <th class="primary--text">
                  Especialidad
                </th>
                <th class="primary--text text-center">
                  Cantidad de órganos jurisdiccionales
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in arraySuma"
                :key="index"
              >
                <td
                  v-if="item.cantidad > 0"
                  class="text-left font-weight-bold teal--text"
                >
                  {{ item.nombre }}
                </td>
                <td
                  v-if="item.cantidad > 0"
                  class="text-center font-weight-bold primary--text"
                >
                  {{ item.cantidad }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </base-material-card>
        <base-material-stats-card
          color="yellow darken-3"
          icon="mdi-trophy"
          title="1er PUESTO"
          :value="primerPuesto.nombre"
          :value2="primerPuesto.juez"
          sub-icon="mdi-flag"
          sub-icon-color="yellow darken-3"
          :sub-text="primerPuesto.prod"
          :sub-text2="(primerPuesto.porcentaje_anual_meta_anual * 100).toFixed(2)"
          :mes="fechaActualizacion"
          :cod-dep="primerPuesto.id_juzgado"
        />
      </v-col>
      <v-col
        cols="12"
        md="9"
      >
        <base-material-card
          color="teal"
          icon="mdi-podium-gold"
          :title="tituloTop"
          class="px-5 py-3"
        >
          <div
            class="d-flex flex-column align-center"
          >
            <v-col
              cols="12"
              md="12"
            >
              <!-- <span
                v-for="(item, index) in juzgadosData"
                :key="item.mes"
                class="teal--text"
              >
                <span v-if="produccionMensual[index] >0">{{ meses[index] }}: <strong>{{ produccionMensual[index] }}</strong> expedientes resueltos<br></span>
              </span> -->
              <v-simple-table dense>
                <thead>
                  <tr>
                    <th class="primary--text">
                      Juzgado
                    </th>
                    <th class="primary--text text-center">
                      Porcentaje*
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in juzgadosData.slice(0, 10)"
                    :key="index"
                  >
                    <td
                      class="text-left font-weight-bold teal--text"
                      @click="$router.push(`/prod/${item.id_juzgado}_${dataUsuario.sede}_${anoSeleccionado}/`)"
                    >
                      <span
                        class="red--text"
                      >#{{ index + 1 }}</span> {{ item.nombre }}
                    </td>
                    <td class="text-center font-weight-bold primary--text">
                      <!-- {{ parseInt(item.mes) === parseInt(mes) +1 ? (item.porcentaje_anual_meta_anual * 100).toFixed(0) : (item.porcentaje_anual * 100).toFixed(0) }}%  -->
                      {{ (item.porcentaje_anual_meta_anual * 100).toFixed(0) }}%
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </div>
          <template v-slot:actions>
            <span
              class="text-caption"
            >
              <h4 class="d-inline-flex font-weight-bold">
                El estándar de producción se encuentra definido por el Consejo Ejecutivo del Poder Judicial en conformidad a la resolución N° 395-2020 y es aplicable exclusivamente para juzgados con metas establecidas.
              </h4>
              <span><br>*Porcentaje con respecto a la meta anual</span>
              <br>Actualizado hasta {{ fechaActualizacion }}
            </span>
          </template>
        </base-material-card>
      </v-col>
      <v-col
        cols="12"
        md="9"
      >
        <base-material-card
          icon="mdi-seal"
          color="teal"
          title="PRODUCCIÓN DETALLADA"
          class="px-5 py-3"
        >
          <!-- <v-combobox
            v-model="tipoOrganosRanking"
            :items="itemsTipos"
            label="Ranking por especialidad"
            @input="leerJuzgados(tipoOrganos)"
          /> -->
          <h5 class="d-inline-flex font-weight-light ml-2 mt-1">
            El estándar de producción se encuentra definido por el Consejo Ejecutivo del Poder Judicial en conformidad a la resolución N° 395-2020 y es aplicable exclusivamente para juzgados con metas establecidas.
            <br>*Meta actual hasta el mes de {{ meses[mes] }} <br>
            IMPORTANTE: El siguiente ranking es referencial.
          </h5>
          <v-simple-table
            fixed-header
            height="800px"
          >
            <thead>
              <tr>
                <th class="primary--text">
                  Puesto
                </th>
                <th class="primary--text text-center">
                  Nombre
                </th>
                <th class="primary--text text-center">
                  Producción actual
                </th>
                <th class="primary--text text-center">
                  Meta actual
                </th>
                <th class="primary--text text-center">
                  Meta anual
                </th>
                <th class="primary--text text-center">
                  Meta anual proyectada
                </th>
                <th class="primary--text text-center">
                  Avance anual
                </th>
                <!-- <th class="primary--text text-center">
                  Avance mensual
                </th> -->
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(item, index) in juzgadosData"
                :key="index"
              >
                <td
                  class="text-center primary--text font-weight-bold"
                >
                  {{ index + 1 }}
                </td>
                <td
                  :class="item.restriccion ? 'red--text text-left font-weight-bold ' : 'texl-left'"
                  @click="$router.push(`/prod/${item.id_juzgado}_${dataUsuario.sede}_${anoSeleccionado}/`)"
                >
                  {{ item.restriccion ? item.nombre + ' (CONSULTAR CON ESTADÍSTICA)' : item.nombre }}
                </td>
                <td
                  class="text-center"
                >
                  {{ item.prod }}
                </td>
                <td
                  class="text-center"
                >
                  {{ item.mensual }} {{ ((item.meta_anual/11) * (item.febrero != 0 ? (item.mes - 1) : (item.mes))).toFixed(0) }}
                </td>
                <td
                  class="text-center"
                >
                  {{ item.meta_anual }}
                </td>
                <td
                  class="text-center"
                >
                  {{ parseInt(item.meta_anual_calculado) }}
                </td>
                <td
                  class="text-center"
                >
                  <!-- {{ parseInt(item.mes) === parseInt(mes) + 1 ? (item.porcentaje_anual_meta_anual * 100).toFixed(2) : (item.porcentaje_anual * 100).toFixed(2) }}% -->
                  {{ (item.porcentaje_anual_meta_anual * 100).toFixed(2) }}%
                </td>
                <!-- <td
                  class="text-center"
                >
                  {{ (item.porcentaje_mensual * 100).toFixed(2) }}%
                </td> -->
              </tr>
            </tbody>
          </v-simple-table>
        </base-material-card>
      </v-col>
    </v-row>
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        md="3"
      >
        <v-combobox
          v-model="anoSeleccionado"
          :items="anosAnteriores"
          label="Consulte ranking de años anteriores"
        />
      </v-col>
      <v-btn
        color="primary"
        small
        @click="irProduccionAno(anoSeleccionado)"
      >
        CONSULTAR
        <v-icon right>
          mdi-clock
        </v-icon>
      </v-btn>
    </v-row>
    <br>
    <div
      class="text-body-1 text-center"
      @click="$router.push('/cerrar_sesion')"
    >
      {{ new Date().getFullYear() }} &copy; CSJLA
    </div>
    <br>
    <v-row
      justify="center"
    >
      <v-btn
        color="primary"
        depressed
        @click="irInicio()"
      >
        INICIO
        <v-icon right>
          mdi-home
        </v-icon>
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios'
  export default {
    name: 'Produccion',
    data () {
      return {
        // itemsTipos: ['Todos', 'Penal', 'Familia', 'Civil', 'Laboral', 'Paz Letrado', 'Mixto'],
        itemsTipos: [],
        meses: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Setiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        carga: false,
        url: '',
        urlJuzgado: '',
        urlLogo: '',
        dataSede: '',
        urlSede: '',
        anoRanking: '',
        juzgadosData: [],
        dataJuzgado: [],
        anosAnteriores: [],
        anoSeleccionado: '',
        juzgado1: '',
        juzgado1Prod: '',
        sumatoria: [],
        arraySuma: [],
        juez: '',
        primerPuesto: {},
        mes: '',
        fechaActualizacion: '',
        tipoOrganos: '',
        tipoOrganosRanking: '',
        tituloTop: '',
        itemsSubEspecialidad: [],
        tipoSubEspecialidad: '',
        subEspecialidad: [],
        infoTotal: [],
        produccionJuzgado: {
          backgroundColors: ['info', 'primary', 'dark', 'secondary', 'primary'],
          data: {
            // labels: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Set', 'Oct', 'Nov', 'Dic'],
            labels: [],
            series: [],
          },
          options: {
            donut: true,
            donutWidth: 30,
            donutSolid: true,
            startAngle: 270,
          },
        },
      }
    },
    created () {
      this.dataUsuario = JSON.parse(localStorage.getItem('usuarioDataCSJLA'))
      if (!this.dataUsuario) {
        localStorage.removeItem('usuarioDataCSJLA')
        this.$router.push('/')
      }
      this.$store.commit('SET_VALOR', true)
      this.$store.commit('SET_LOGO', true)

      this.sedeCorteData = '17'
      this.urlJuzgado = ''
      this.currentYear = new Date().getFullYear()
      this.anoSeleccionado = this.currentYear
      this.leerInfoProduccion(+this.currentYear, 'TODOS')
      window.scrollTo(0, 0)
      this.leerActualizacion()
      // this.anosAnteriores.push(this.currentYear, this.currentYear - 1, this.currentYear - 2, this.currentYear - 3)
    },
    methods: {
      leerInfoProduccion (ano, tipo) {
        tipo ? this.tipoOrganos = tipo : this.tipoOrganos = 'TODOS'
        this.carga = true
        this.anoRanking = ano
        this.produccionJuzgado.data.labels = []
        this.dataJuzgado = []
        this.juzgadosData = []
        this.tipoSubEspecialidad = ''
        axios
          .get(`${window.__env.dataCfg.urlApiData}ranking/${ano}/`)
          .then(response => {
            this.leerAnos()
            if (response.data.resp === false) {
              alert('SELECCIONE UN AÑO DISTINTO')
              this.carga = false
              this.juzgadosData = []
              this.infoTotal = []
            }
            const result = response.data.array.reduce((acc, item) => {
              if (!acc.includes(item.especialidad)) {
                acc.push(item.especialidad)
              }
              return acc
            }, [])
            result.sort()
            result.unshift('TODOS')
            this.itemsTipos = result
            if (response.data.resp === false) {
              this.carga = false
              alert('NO SE HA ENCONTRADO INFORMACIÓN SOBRE LA PRODUCCIÓN DEL JUZGADO')
            } else {
              this.carga = false
              if (tipo === 'TODOS') {
                this.juzgadosData = response.data.array
                this.infoTotal = response.data.array
              } else {
                this.juzgadosData = response.data.array.filter(data => data.especialidad === tipo)
                this.infoTotal = response.data.array.filter(data => data.especialidad === tipo)
              }
              /* console.log(response.data.array.filter(data => data.especialidad === tipo))
              console.log(response.data.array.filter(data => data.especialidad === tipo).length)
             */
              /* if (tipo === 'PENAL') {
                response.data.array.forEach((el) => {
                  if (el.nombre.includes('PENAL')) {
                    this.juzgadosData.push(el)
                  }
                })
              } else if (tipo === 'Civil') {
                response.data.array.forEach((el) => {
                  if (el.nombre.includes('CIVIL')) {
                    this.juzgadosData.push(el)
                  }
                })
              } else if (tipo === 'Laboral') {
                response.data.array.forEach((el) => {
                  if (el.nombre.includes('LABORAL')) {
                    this.juzgadosData.push(el)
                  }
                })
              } else if (tipo === 'Familia') {
                response.data.array.forEach((el) => {
                  if (el.nombre.includes('FAMILIA')) {
                    this.juzgadosData.push(el)
                  }
                })
              } else if (tipo === 'Paz Letrado') {
                response.data.array.forEach((el) => {
                  if (el.nombre.includes('PAZ LETRADO CIVIL')) {
                    this.sumatoria['JUZGADOS DE PAZ LETRADO CIVIL']++
                  } else if (el.nombre.includes('PAZ LETRADO FAMILIA')) {
                    this.sumatoria['JUZGADOS DE PAZ LETRADO FAMILIA']++
                  } else if (el.nombre.includes('PAZ LETRADO LABORAL')) {
                    this.sumatoria['JUZGADOS DE PAZ LETRADO LABORAL']++
                  } else if (el.nombre.includes('PAZ LETRADO PENAL')) {
                    this.sumatoria['JUZGADOS DE PAZ LETRADO PENAL']++
                  } else if (el.nombre.includes('JUZGADO DE PAZ LETRADO')) {
                    this.juzgadosData.push(el)
                  }
                })
              } else if (tipo === 'Mixto') {
                response.data.array.forEach((el) => {
                  if (el.nombre.includes('JUZGADO MIXTO') || el.nombre.includes('MIXTA')) {
                    this.juzgadosData.push(el)
                  }
                })
              } else {
                this.juzgadosData = response.data.array
              } */
              // this.produccionJuzgado.data.labels.push((this.juzgadosData[0].nombre))
              this.primerPuesto = this.juzgadosData[0]
              this.mes = +this.juzgadosData[0].mes - 1
              this.juez = +this.anoSeleccionado === +this.currentYear ? this.juzgadosData[0].juez : ''
              // this.dataJuzgado.push((this.juzgadosData[0].porcentaje_anual * 100).toFixed(0))
              // this.produccionJuzgado.data.series = [this.dataJuzgado]
              // this.produccionJuzgado.options.high = +(this.juzgadosData[0].porcentaje_anual * 100).toFixed(0) + 10
              this.carga = false
              window.scrollTo(0, 0)
              this.leerJuzgados(tipo)
            }
          })
          .catch(e => {
            console.log(e) //eslint-disable-line
          })
      },
      leerJuzgados (tipo) {
        this.arraySuma = []
        this.itemsTipos.forEach((el) => {
          this.sumatoria[`${el}`] = 0
          this.sumatoria[`${el}`] = this.juzgadosData.filter(data => data.especialidad === el).length
        })
        // this.juzgadosData.filter(data => data.especialidad === tipo)
        this.sumatoria[`${tipo}`] = this.juzgadosData.filter(data => data.especialidad === tipo).length
        /* this.sumatoria['JUZGADOS CIVILES'] = 0
        this.sumatoria['JUZGADOS PENALES'] = 0
        this.sumatoria['JUZGADOS LABORALES'] = 0
        this.sumatoria['JUZGADOS DE FAMILIA'] = 0
        this.sumatoria['JUZGADOS DE PAZ LETRADO'] = 0
        this.sumatoria['JUZGADOS MIXTOS'] = 0
        this.sumatoria['SALAS CIVILES'] = 0
        this.sumatoria['SALAS PENALES'] = 0
        this.sumatoria['SALAS LABORALES'] = 0
        this.sumatoria['SALAS MIXTAS'] = 0
        this.sumatoria['JUZGADOS DE PAZ LETRADO CIVIL'] = 0
        this.sumatoria['JUZGADOS DE PAZ LETRADO FAMILIA'] = 0
        this.sumatoria['JUZGADOS DE PAZ LETRADO LABORAL'] = 0
        this.sumatoria['JUZGADOS DE PAZ LETRADO PENAL'] = 0

        for (const juz of this.juzgadosData) {
          if (juz.nombre.includes('JUZGADO CIVIL')) {
            this.sumatoria['JUZGADOS CIVILES']++
          } else if (juz.nombre.includes('JUZGADO PENAL')) {
            this.sumatoria['JUZGADOS PENALES']++
          } else if (juz.nombre.includes('JUZGADO LABORAL')) {
            this.sumatoria['JUZGADOS LABORALES']++
          } else if (juz.nombre.includes('PAZ LETRADO CIVIL')) {
            this.sumatoria['JUZGADOS DE PAZ LETRADO CIVIL']++
          } else if (juz.nombre.includes('PAZ LETRADO FAMILIA')) {
            this.sumatoria['JUZGADOS DE PAZ LETRADO FAMILIA']++
          } else if (juz.nombre.includes('PAZ LETRADO LABORAL')) {
            this.sumatoria['JUZGADOS DE PAZ LETRADO LABORAL']++
          } else if (juz.nombre.includes('PAZ LETRADO PENAL')) {
            this.sumatoria['JUZGADOS DE PAZ LETRADO PENAL']++
          } else if (juz.nombre.includes('JUZGADO DE PAZ LETRADO')) {
            this.sumatoria['JUZGADOS DE PAZ LETRADO']++
          } else if (juz.nombre.includes('SALA CIVIL')) {
            this.sumatoria['SALAS CIVILES']++
          } else if (juz.nombre.includes('SALA PENAL')) {
            this.sumatoria['SALAS PENALES']++
          } else if (juz.nombre.includes('SALA LABORAL')) {
            this.sumatoria['SALAS LABORALES']++
          } else if (juz.nombre.includes('JUZGADO DE FAMILIA')) {
            this.sumatoria['JUZGADOS DE FAMILIA']++
          } else if (juz.nombre.includes('SALA MIXTA')) {
            this.sumatoria['SALAS MIXTAS']++
          } else if (juz.nombre.includes('JUZGADO MIXTO')) {
            this.sumatoria['JUZGADOS MIXTOS']++
          }
        } */
        if (tipo === 'TODOS') {
          this.itemsTipos.shift()
          this.itemsTipos.forEach((el, index) => {
            this.arraySuma.push({ cantidad: this.sumatoria[`${el}`], nombre: `${el}`, leyenda: `${el}` })
          /* this.arraySuma.forEach((el, index) => {
            var color = `ct-laguna-pie${index}`
            this.produccionJuzgado.data.labels.push(el.leyenda)
            this.produccionJuzgado.data.series.push({ className: color, value: el.cantidad })
          }) */
          })
          this.arraySuma = this.arraySuma.sort((p1, p2) => (p1.cantidad < p2.cantidad) ? 1 : (p1.cantidad > p2.cantidad) ? -1 : 0)
          // console.log(this.arraySuma.sort((p1, p2) => (p1.cantidad < p2.cantidad) ? 1 : (p1.cantidad > p2.cantidad) ? -1 : 0))
        } else {
          const result = this.juzgadosData.reduce((acc, item) => {
            if (!acc.includes(item.tipo)) {
              acc.push(item.tipo)
            }
            return acc
          }, [])
          result.sort()
          this.sumatoria = []
          result.forEach((el) => {
            this.sumatoria[`${el}`] = 0
            this.sumatoria[`${el}`] = this.juzgadosData.filter(data => data.tipo === el).length
            this.arraySuma.push({ nombre: `${el}`, cantidad: this.sumatoria[`${el}`] })
          })
          this.itemsSubEspecialidad = this.juzgadosData.reduce((acc, item) => {
            if (!acc.includes(item.sub_especialidad)) {
              acc.push(item.sub_especialidad)
            }
            return acc
          }, [])
          /*
          this.juzgadosData.forEach((el, index) => {
            this.arraySuma.push({ nombre: `${el.tipo}`, cantidad: this.sumatoria[`${el}`] })
          })
          this.arraySuma.push({ nombre: `${tipo}`, leyenda: `${tipo}`, cantidad: this.sumatoria[`${tipo}`] })
          console.log('kjasdkasjd') */
        }
        /* } else if (tipo === 'Civil') {
          this.arraySuma.push({ nombre: 'SALAS', leyenda: 'SALAS', cantidad: this.sumatoria['SALAS CIVILES'] }, { nombre: 'JUZGADOS ESPECIALIZADOS', leyenda: 'JC', cantidad: this.sumatoria['JUZGADOS CIVILES'] }, { nombre: 'JUZGADOS DE PAZ LETRADO (JDP)', leyenda: 'JDP', cantidad: this.sumatoria['JUZGADOS DE PAZ LETRADO CIVIL'] })
          this.arraySuma.forEach((el, index) => {
            var color = `ct-laguna-pie${index}`
            this.produccionJuzgado.data.labels.push(el.leyenda)
            this.produccionJuzgado.data.series.push({ className: color, value: el.cantidad })
          })
        } else if (tipo === 'Penal') {
          this.arraySuma.push({ nombre: 'SALAS', leyenda: 'SALAS', cantidad: this.sumatoria['SALAS PENALES'] }, { nombre: 'JUZGADOS ESPECIALIZADOS', leyenda: 'JP', cantidad: this.sumatoria['JUZGADOS PENALES'] }, { nombre: 'JUZGADOS DE PAZ LETRADO (JDP)', leyenda: 'JDP', cantidad: this.sumatoria['JUZGADOS DE PAZ LETRADO PENAL'] })
          this.arraySuma.forEach((el, index) => {
            var color = `ct-laguna-pie${index}`
            this.produccionJuzgado.data.labels.push(el.leyenda)
            this.produccionJuzgado.data.series.push({ className: color, value: el.cantidad })
          })
        } else if (tipo === 'Familia') {
          this.arraySuma.push({ nombre: 'JUZGADOS ESPECIALIZADOS', leyenda: 'JF', cantidad: this.sumatoria['JUZGADOS DE FAMILIA'] }, { nombre: 'JUZGADOS DE PAZ LETRADO (JDP)', leyenda: 'JDP', cantidad: this.sumatoria['JUZGADOS DE PAZ LETRADO FAMILIA'] })
          this.arraySuma.forEach((el, index) => {
            var color = `ct-laguna-pie${index}`
            this.produccionJuzgado.data.labels.push(el.leyenda)
            this.produccionJuzgado.data.series.push({ className: color, value: el.cantidad })
          })
        } else if (tipo === 'Laboral') {
          this.arraySuma.push({ nombre: 'SALAS', leyenda: 'SALAS', cantidad: this.sumatoria['SALAS LABORALES'] }, { nombre: 'JUZGADOS ESPECIALIZADOS', leyenda: 'JL', cantidad: this.sumatoria['JUZGADOS LABORALES'] }, { nombre: 'JUZGADOS DE PAZ LETRADO (JDP)', leyenda: 'JDP', cantidad: this.sumatoria['JUZGADOS DE PAZ LETRADO LABORAL'] })
          this.arraySuma.forEach((el, index) => {
            var color = `ct-laguna-pie${index}`
            this.produccionJuzgado.data.labels.push(el.leyenda)
            this.produccionJuzgado.data.series.push({ className: color, value: el.cantidad })
          })
        } else if (tipo === 'Paz Letrado') {
          this.arraySuma.push({ nombre: 'JUZGADOS DE PAZ LETRADO (JDP)', leyenda: 'JDP', cantidad: this.sumatoria['JUZGADOS DE PAZ LETRADO'] })
          this.arraySuma.forEach((el, index) => {
            var color = `ct-laguna-pie${index}`
            this.produccionJuzgado.data.labels.push(el.leyenda)
            this.produccionJuzgado.data.series.push({ className: color, value: el.cantidad })
          })
        } else if (tipo === 'Paz Letrado') {
          this.arraySuma.push({ nombre: 'JUZGADOS DE PAZ LETRADO (JDP)', leyenda: 'JDP', cantidad: this.sumatoria['JUZGADOS DE PAZ LETRADO'] })
          this.arraySuma.forEach((el, index) => {
            var color = `ct-laguna-pie${index}`
            this.produccionJuzgado.data.labels.push(el.leyenda)
            this.produccionJuzgado.data.series.push({ className: color, value: el.cantidad })
          })
        } else if (tipo === 'Mixto') {
          this.arraySuma.push({ nombre: 'SALAS', leyenda: 'SALAS', cantidad: this.sumatoria['SALAS MIXTAS'] }, { nombre: 'JUZGADOS MIXTOS (JM)', leyenda: 'JM', cantidad: this.sumatoria['JUZGADOS MIXTOS'] })
          this.arraySuma.forEach((el, index) => {
            var color = `ct-laguna-pie${index}`
            this.produccionJuzgado.data.labels.push(el.leyenda)
            this.produccionJuzgado.data.series.push({ className: color, value: el.cantidad })
          })
        } */
        // this.leerInfoProduccion(this.anoSeleccionado, tipo)
        // this.listaJuzgados[arr[i].nombre] = this.listaJuzgados[arr[i].nombre].concat(this.juzgadosData.filter(obj => obj.nombre.toLowerCase().includes(arr[i].busca.split(',')[l].toLowerCase())))
      },
      leerDataSubespecialidad (sub) {
        this.sumatoria = []
        this.arraySuma = []
        this.juzgadosData = this.infoTotal
        var tipos = this.juzgadosData.reduce((acc, item) => {
          if (!acc.includes(item.tipo)) {
            acc.push(item.tipo)
          }
          return acc
        }, [])
        this.sumatoria[`${sub}`] = this.juzgadosData.filter(data => data.sub_especialidad === sub)
        this.juzgadosData = this.sumatoria[`${sub}`]
        this.primerPuesto = this.juzgadosData[0]
        tipos.forEach((el) => {
          this.sumatoria[`${el}`] = 0
          this.sumatoria[`${el}`] = this.sumatoria[`${sub}`].filter(data => data.tipo === el).length
          this.arraySuma.push({ nombre: `${el}`, cantidad: this.sumatoria[`${el}`] })
        })
      },
      leerAnos () {
        this.urlAnos = `${window.__env.dataCfg.urlApiData}produccion/anos/`
        axios
          .get(this.urlAnos)
          .then(response => {
            response.data.array.forEach(element => this.anosAnteriores.push(element.ano_meta))
            console.log(response)
          })
          .catch(e => {
            console.log(e) //eslint-disable-line
          })
      },
      leerActualizacion () {
        this.urlAnos = `${window.__env.dataCfg.urlApiData}produccion/actualizacion/`
        axios
          .get(this.urlAnos)
          .then(response => {
            this.fechaActualizacion = this.formatDate('DD/MM/YYYY', response.data.array[0].fecha_actualizacion)
            this.tituloTop = 'TOP 10'
          })
          .catch(e => {
            console.log(e) //eslint-disable-line
          })
      },
      formatDate (template, date) {
        const specs = 'YYYY:MM:DD:HH:mm:ss'.split(':')
        date = new Date(
          date || Date.now() - new Date().getTimezoneOffset() * 6e4,
        )
        return date
          .toISOString()
          .split(/[-:.TZ]/)
          .reduce(
            (template, item, i) =>
            // eslint-disable-line
              template.split(specs[i]).join(item),
            template,
          )
      },
      irSede () {
        this.$router.push(`/sede/${this.sedeCorteData}/`)
      },
      irInicio () {
        this.$router.push('/')
      },
      irProduccionAno (ano) {
        if (ano) {
          this.leerInfoProduccion(+this.anoSeleccionado, this.tipoOrganos)
        } else {
          alert('SELECCIONE AÑO')
        }
      },
    },
  }
</script>
